html {
  scroll-behavior: smooth;
}

body {
  font-family: "Ubuntu", sans-serif;
  font-size: 16px;
  color: var(--text-color);
}

// Utilities

*{ box-sizing: border-box; }

.flex {
  display: flex;
}

a, a:visited{
  color: var(--text-color);
}
p {
  margin-bottom: 0.5rem;
  line-height: 1.5rem;
}
hr {
  margin: 1rem 0;

  height: 1px;
  border-width: 0;
  background-color: var(--text-color2);
  width: 100%;
}
h1, h2, h3, h4, h5, h6{
  font-family: "Dosis", sans-serif;
}
h1 {
  font-size: 3rem;
  margin: 0.3rem 0;
  line-height: 3.1rem;
}
h2 {
  font-size: 1.8rem;
  // font-weight: bold;
  line-height: 2.1rem;
}
h3 {
  font-size: 1.6rem;
  line-height: 1.8rem;
}
h4 {
  font-size: 1.3rem;
  margin: 0.8rem 0;
  font-weight: bold;
}
h5{
  font-size: 1.2rem;
}
.bold {
  font-weight: bold;
}
.invert {
  color: var(--background-color);
  background-color: var(--text-color);
  padding: 0 5px;
}
.invert2 {
  color: var(--background-color2);
  background-color: var(--text-color2);
  padding: 0 5px;
}

.arrowbtn{
  &::after {
    display: inline-block;
    content: "\F035F";
    font-family: "Material Design Icons";
    margin-left: 0.5rem;
    transition: all linear 200ms;
  }

  &:hover {
    &::after {
      transform: rotate(90deg);
    }
  }
}

.btn {
  margin-top: 0;
  box-shadow: var(--box-shadow);
  background-color: transparent;
  border: 1px solid var(--text-color);
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
  color: var(--text-color);
  font-family: "Dosis", sans-serif;
  font-size: 1rem;
  padding: 14px 27px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #263666;
  position: relative;
  box-sizing: content-box;
}

.btn2,
.btn2:visited {
  border: 1px solid var(--text-color2);
  color: var(--text-color2);
}

.noDelay {
  transition-delay: 0ms !important;
}
.hidden {
  visibility: hidden;
}
.visible {
  visibility: visible !important;
}
.displaynone {
  display: none !important;
}
