@import "./carousel.scss";

.transition {
  transition: all .7s;
}

.modal-wrap {
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  z-index: 100;
  transition: opacity .3s;
  pointer-events: none;
  top: 0;

  &.visible {
    opacity: 1;
    pointer-events: initial;
  }

  &>#modal {
    top: -5vh;
    position: relative;
    margin-top: 2rem;
    width: $slideWidth;
    height: fit-content;
    background-color: #fff;
    box-shadow: var(--box-shadow);

    @include responsive(phone) {
      width: 100%;
    }

    & > .binder {
      flex-direction: column;
    }
  }

  // Project info
  & .infos {
    position: relative;
    height:auto;
    & h6,
    & p {
      margin-bottom: 1rem;
    }

    & .links {
      gap: 1rem;

      & a {
        display: none;

        &.visible {
          display: block;
        }
      }
    }

    &>.box {
      margin: 2rem 1rem 2.8rem 1rem;
      position: relative;
    }
  }
}