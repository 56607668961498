@import "./slider.css";
@import "./mediaQueries.scss";
@import "./portfolioHomeDisplay.scss";
@import "./roots.scss";
@import "./menu.scss";
@import "./modal-data.scss";
@import "./util.scss";
@import "./loading.css";

// Content

#wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @include responsive(tablet) {
    flex-direction: column;
  }

}

.container {
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

main {
  position: relative;
  width: var(--main-witdth);
  background-color: var(--background-color);

  @include responsive(tablet) {
    width: 100%;
  }
}

// SECTIONS

section p:not(:last-child) {
  margin-bottom: 1rem;
}


// SPECFIC SECTIONS 

section:not(#home) {
  &>div {
    padding: 25px;
    width: 100%;
    padding-top: .5rem;

    &>h1 {
      margin: 0 0 2rem 0;
      position: relative;

      @include responsive(phone) {
        text-align: center;
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background: var(--text-color);
        top: calc(100% + .4rem);
        left: 0;
      }
    }

    &>h2 {
      text-align: center;
      margin: 1.5rem 0;
      position: relative;
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: left;

      @include responsive(phone) {
        justify-content: center;
      }

      &::before,
      &::after {
        content: "";
        width: 2rem;
        height: 1px; // suit your need
        background: var(--text-color); // suit your need
        margin-bottom: -.4rem;
      }

      &::before {
        margin-right: 10px;

        @include responsive(phone) {
          width: 100%;
        }
      }

      &::after {
        margin-left: 10px;
        width: 100%;
      }
    }
  }
}

section#home {
  position: relative;
  background-color: var(--background-color);
  font-family: Dosis;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &::after,
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
    }

    &::before{
      background: url(../images/home/fishing.jpg) center center/cover no-repeat;
      filter: contrast(1.05) grayscale(1);
      opacity: 1;
    }

    &::after {
      background-color: var(--background-color);
      background: url(../images/pngegg.png) 5% repeat;
      opacity: .1;
    }

  & .headline {
    position: relative;
    padding: 1rem;

    & h1 {
      color: var(--text-color);
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    }

    @include responsive(phone) {
      & h1 {
        text-align: center;
      }
    }

    & p {
      text-align: right;
      margin-right: 0.3rem;
    }
  }

  & .btn {
    margin-top: 1.5rem;
    background-color:rgba(255, 255, 255, 0.5);
    z-index: 10;
  }
}

// section height adjust for responsive layout

section#portfolio {
  &>div {
    @include responsive(tablet) {
      padding-top: calc(15px + 65px);
    }
  }
}

section#portfolio {
  $displayWidth: 330px;

  flex-direction: column;
  align-items: center;

  & .projects {
      flex-wrap: wrap;
      justify-content: center;

      &>div {
        width: $displayWidth;
        height: 300px;
        position: relative;
        cursor: pointer;

        & h4 {
          margin: .2rem;
        }

        & a,
        a:visited {
          text-decoration: none;
        }

        & p {
          margin-top: .5rem;
          margin-bottom: 0;
        }

        & .description {
          box-shadow: var(--box-shadow);
          width: 100%;
          height: 100%;
          padding: 15px;
          text-align: center;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          background: var(--background-color2);
          color: var(--text-color2);
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transition: all 300ms ease-out;

          & h4 {
            margin-top: 3rem;
          }

          & h6 {
            margin-bottom: auto;
          }

          & .btn {
            position: absolute;
            bottom: 0;
            margin-bottom: 0;
            font-size: 1rem;
            pointer-events: none;
            transition: all 300ms ease-out;
          }
        }

        &:hover {
          & .description {
            opacity: 1;

            &:hover,
            &:active {
              & .btn {
                bottom: 2rem;
              }
            }
          }
        }
      }

      @include portfolio-items;
  }

  & .studyProjects {
    
    & > .container {
      gap: .5rem;

      @include responsive(phone) {
        flex-direction: column;
        align-items: center;
      }
    }

    & .card {
      // border: 1px solid;
      border-color: rgb(136, 136, 136);
      position: relative;
      justify-content: center;
      text-align: center;
      transition: all 350ms ease-in-out;
      
      flex: 1;
      overflow: hidden;
      height: 330px;
      
      @include responsive(phone){
        height: 200px;
        width: 300px;
      }

      // @include portfolio-projects;

      & img {
       // visibility:  hidden;
        object-fit: fill;
        filter: grayscale(1);

        @include responsive(phone){
          filter: none;
          object-fit: cover;
          width: 330px;
          height: 300px;
        }
      }

      & .description {
        opacity: 0;
        transition: opacity .3s ease-in-out;
        transition-delay: .2s;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        width: 100%;
        color: white;
        background-color: rgba(0, 0, 0, 0.8);
        justify-content: center;
        align-items: center;
        padding: 1.5rem 0;

        @include responsive(phone) {
          height: 100%;

          & h5 {
            margin-top: 1rem;
          }
          
          & a.btn {
            margin-top: auto;
          }
        }

        & .btn{
          margin-top: 1.5rem;
          pointer-events: none;
        }
        
        & h6 {
          margin-top: 0;
        }
      }

      &:hover {
        flex: 2 1;

        & img {
          filter: none;
        }

        & .description {
          opacity: 1;
        }
      }
    }
  }
}

section#knowledge {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color2);
  color: var(--text-color2);
  // height: 100vh;

  & a,
  a:visited {
    color: var(--text-color2);
  }

  &>div {
    padding-bottom: 0;
    &>h1 {
      &::before {
        content: "we don't talk about bruno~";
        position: absolute;
        font-size: 0.8rem;
        right: 0;
        top: 1.5rem;
        opacity: 0.6;
        font-style: italic;

        @include responsive(phone) {
          width: 100%;
          font-size: 0.7rem;
          display: flex;
          justify-content: center;
          top: 90%;
        }
      }

      &::after {
        background: var(--text-color2);
      }
    }
  }

  & .container {
    justify-content: space-around;
    flex-wrap: wrap;
    row-gap: 3rem;

    &>.card {
      flex-direction: row;
      align-items: center;

      &>.card-icon {
        text-align: center;
        width: 100px;
      }

      &>.card-title {
        padding: 0 1.2rem;
        text-align: center;

        &>h2 {
          margin: 0;
        }
      }

      @include responsive(phone) {
        flex-direction: column;
        width: 152px;
      }
    }

  }
}

section#aboutme {
  background-color: var(--background-color2);
  color: var(--text-color2);

  &>div {

    &>h1 {
      &::after {
        background: var(--text-color2);
      }
    }

    &>h2 {
      margin-top: 0;
      &::before,
      &::after {
        background: var(--text-color2);
      }
    }
  }

  & .container {
    gap: 10px;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  & .firstblock {
    width: 45%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include responsive(tablet) {
      width: 100%;
    }

    & .profile {
      width: 100%;
      height: 100%;
      height: auto;
      min-width: 323px;
    }
  }

  & .secondblock {
    width: 45%;
    flex-direction: column;
    justify-content: center;
    text-align: justify;

    @include responsive(tablet) {
      width: 100%;
    }
  }

  & .about-info {
    font-family: "Dosis", sans-serif;
    margin: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    & li {
      display: inline-block;
      width: 34%;

      & span {
        font-weight: bold;
      }
    }
  }
}

section#contact {
  height: 100vh;

  & > div {
    height: calc(100% - 25px - 0.5rem - 2rem);
    position: relative ;
  }

  & .container {
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    & > div:first-child {
      text-align: center;
    }

    & .infos {
      position: relative;
      justify-content: center;
      
      & .contactinfo {
        flex: 1;
        & ul {
          flex-direction: column;
          gap: .5rem;

          & li {
            text-align: right;
            flex-direction: row-reverse;
            width: 100%;
          
            & p {
              display: inline-block;
              margin-bottom: 0;
            }

            @include responsive(tablet){
              text-align: left;
              flex-direction: row;
              justify-content: center;
              & div{
                width:150px;
              }
            }
          }
        }

        & i {
          margin: .5rem 0 0 1rem ;
          font-size: 1.5rem;

          @include responsive(tablet){
            margin-right: .5rem;
          }
        }

        @include responsive(tablet){
          margin-bottom: 50%;
        }
      }

      & footer {
        flex: 1;
        align-items: center;
        justify-content: left;
        gap: 2rem;

        & i {
          font-size: 1.7rem;
          &:hover {
            color: var(--alt-text-logo);
          }
        }

        @include responsive(tablet){
          justify-content: center;
        }
      }

      @include responsive(tablet) {
        flex-direction: column;
      }
    }

    @include responsive(tablet){
      & > div:last-child {
        display: none;
      }
    }
  }

  & hr {
    background-color: var(--text-color);
    transform: rotate(90deg);
    position: relative;
    width: auto;

    @include responsive(tablet){
      display: none;
    }
  }

  @include responsive(tablet){
    height: calc(100vh - 65px);
  }
}

// FUNCTIONS

// Theme Selector

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.loader {
  transition: opacity .3s ease-in-out;
  background-color: white;
  left: 0;
  width: 100%;
  position: fixed;
  height: 100vh;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  color: var(--alt-text-logo);
}