:root {
 --navbar-width: max(200px, 20vw);
 --main-witdth: calc(100% - var(--navbar-width));

 --settings-background: black;
 --settings-color: white;

 --box-shadow: 0px 2px 10px -4px rgba(0,0,0,0.3);
}

// themes

.default {
 //logo colors
 --main-text-logo: #878787;
 --alt-text-logo: #8127b9;
 --mini-logo-img: url("../images/minilogo65.png") center center no-repeat;

 // nav colors
 --nav-background: white;
 --nav-link-color: #474747;
 --nav-active-li: rgba(53, 233, 95, .1);
 --nav-icon-color: #ccc;
 --nav-active-icon: #ab25ff;
 --nav-borderli: #f5f5f5;
 
 // main colors
 --main-background: rgba(129, 39, 185, .06);
 --background-color: #f7f7f7;
 --background-op-color: rgba(247, 247, 247, 0.9);
 --text-color: #474747;
 
 --background-color2: #347f83;
 --background-op-color2: rgba(52, 127, 131, 0.9);
 --text-color2: #f7f7f7;
}