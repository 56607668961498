@keyframes ldio-i2imxilytlf {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}
.ldio-i2imxilytlf div {
  position: absolute;
  animation: ldio-i2imxilytlf 1s linear infinite;
  width: 32px;
  height: 32px;
  top: 84px;
  left: 84px;
  border-radius: 50%;
  box-shadow: 0 4.2px 0 0 #ab25ff;
  transform-origin: 16px 18.1px;
}
.loadingio-spinner-eclipse-j0seu4xr1ga {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #fff;
}
.ldio-i2imxilytlf {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-i2imxilytlf div { box-sizing: content-box; }
/* generated by https://loading.io/ */