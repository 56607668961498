@mixin responsive($size) {
 @if $size == phone {
   @media (max-width: 599px) {
     @content;
   }
 } @else if $size == tablet {
   @media (max-width: 900px) {
     @content;
   }
 } @else if $size == desktop {
   @media (min-width: 1200px) {
     @content;
   }
 }
}