header {
  font-family: 'Dosis', sans-serif;
  flex-direction: column;
  flex-wrap: wrap;
  position: fixed;
  align-items: center;
  gap: 3rem;
  left: 0;
  top: 0;
  height: 100%;
  width: var(--navbar-width);
  background-color: var(--nav-background);
  box-shadow: var(--box-shadow);
  z-index: 1;

  @include responsive(tablet){
    width: 100%;
    height: 65px;
    justify-content: center;
  }

  &::before {
    content: "";
    background: url("../images/brazilmap.png") no-repeat -20px top;
    background-size: 80%;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
  }

  & > nav {
    width: 100%;
    z-index: 1;
  }

  & > .icon-settings {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    color: #8127b9;
    font-size: 1rem;
    cursor: pointer;
    padding: 5px;
  }

  & > div.settings {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    text-align: center;
    opacity: 1;
    visibility: hidden;
    color: var(--settings-color);
    
    z-index: 10;

    &::before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--settings-background);
      opacity: 0.9;
      content: "";
    }

    & h4 {
      margin: 1rem 0;
    }

    & > div {
      position: relative;

      & > .flex {
        gap: 5px;
        margin: 25px 10px;
      }
    }
  }

  & .contact{
    z-index: 1;
    gap: .7rem;


    & i {
      font-size: 1.15rem;
      &:hover {
        color: var(--alt-text-logo);
      }
    }
    @include responsive(tablet){
      display: none;
    }
  }
}

// Logo

#displaylogo {
  margin-top: 50px;
  width: 200px;
  height: 65px;
  justify-content: space-between;
  @include responsive(tablet){
    margin: 0;
  }

  & .imglogo {
    width: 59px;
    height: 65px;
    background: var(--mini-logo-img);
  }

  & .content {
    margin-right: 5px;
  }

  & h2 {
    margin-top: 6px;
    font-family: 'Dosis', sans-serif;
    font-size: 33px;
    letter-spacing: -1.5px;
    color: var(--main-text-logo);
    font-weight: 200;
    font-family: "Dosis";
    text-align: center;
  }
  & hr {
    margin: 2px 0;
    width: 134px;
    background-color: var(--main-text-logo);
  }
  & h4 {
    font-size: 15px;
    color: var(--alt-text-logo);
    font-family: 'Oswald', sans-serif;
    text-align: right;
    letter-spacing: 0.8px;
    margin: 0;
  }
}

// Menu

#mainnav {
  $marginnav: 0.5rem;
  $transitiontime: 200ms;
  background-color: var(--background-color);

  @include responsive(tablet){
    display: none;
  }
  & li {
    font-size: 1.15rem;
    border: {
      bottom: 1px solid var(--nav-borderli);
    }
    padding-left: $marginnav;
    line-height: 2.8rem;
    display: flex;
    gap: 0.5rem;
    background-color: var(--nav-background);
    transition: all ease-out $transitiontime;

    &:first-child {
      border-top: 1px solid var(--nav-borderli);
    }

    & > i {
      font-size: 22px;
      color: var(--nav-icon-color);
      transition: all ease-in-out $transitiontime;
    }

    & > a {
      text-decoration: none;
      display: block;
      height: 100%;
      width: 100%;
      color: var(--nav-link-color);
    }

    &:hover,
    &.active {
      background-color: var(--nav-active-li);

      & i {
        color: var(--nav-active-icon);
      }
    }

    &.active::after {
      content: "\F0142";
      font-size: 20px;
      font-family: "Material Design Icons";
      color: var(--main-text-logo);
      position: absolute;
      right: 10px;
      opacity: 0.5;
      transition: all ease-in $transitiontime;
    }
  }
}
